import React from "react";

const LocalePage = () => {
    return (
        <div>
            <iframe className="locale-iframe" title="locale" src="/locale/" width="100%" height="700"/>
        </div>
    )
};

export default LocalePage;
