import React from "react";

class QrCodeGenerator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            size: 150,
            url: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        }, this.updateQr);

    }

    updateQr() {
        const text = encodeURIComponent(this.state.text);
        const url = `https://chart.googleapis.com/chart?chs=${this.state.size}x${this.state.size}&cht=qr&chl=${text}`;
        this.setState({
            url
        });
    }

    render() {
        let result = '';

        if (this.state.url) {
            result = (
                <div>
                    <img src={this.state.url} alt={'QR code for ' + this.state.text}/>
                    <p><a href={this.state.url}>{this.state.url}</a></p>
                </div>
            );
        }

        return (
            <div>
                <form className="flex-wrapper">
                    <div className="full-width">
                        <label htmlFor="text" className="form-label">Text that the QR code will represent</label>
                        <input name="text" className="form-input form-input-large" onChange={this.handleChange} value={this.state.text}/>
                    </div>

                    <div className="full-width">
                        <label htmlFor="size" className="form-label">Size in pixels of QR code</label>
                        <input name="size" type="number" className="form-input" onChange={this.handleChange} value={this.state.size}/>
                    </div>
                </form>

                {result}
            </div>
        )
    }
}

export default QrCodeGenerator;
