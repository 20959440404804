import React from "react";

const ClocksPage = () => {
    return (
        <div>
            <iframe className="clocks-iframe" title="clocks" src="/clocks/" width="100%" height="700"/>
        </div>
    )
};

export default ClocksPage;
