import React from "react";

const DatesPage = () => {


    return (
        <div>
            <iframe className="dates-iframe" title="dates" src="/dates/" width="100%" height="1500"/>
        </div>
    )
};

export default DatesPage;
