import React from "react"
import {Link} from "gatsby"
import {StaticQuery, graphql} from "gatsby"

const CodeItems = ({data}) => {
    return (
        <ul className="code-list">
            {data.map(post => {
                return (
                    <li className="code-list-item" key={post.frontmatter.slug}>
                        <Link to={post.frontmatter.slug} itemProp="url">{post.frontmatter.title}</Link>
                        {post.frontmatter.description && (<p>{post.frontmatter.description}</p>)}
                    </li>
                )
            })}
        </ul>
    )
};

export default function CodeList() {
    return (
        <StaticQuery
            query={graphql`
        query CodeList {
            allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {contentType: {eq: "code"}}}) {
          nodes {
            excerpt
            frontmatter {
              slug
              date(formatString: "MMMM DD, YYYY")
              title
              description
              featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
            }
          }
        }
    }
    
      `}
            render={data => (
                <div className="content">
                    <h2>Code experiments</h2>
                    <CodeItems data={data.allMarkdownRemark.nodes}/>
                </div>
            )}
        />
    )
}
