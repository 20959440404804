import React from "react";

const paceTimeFormat = (seconds, showSeconds = true) => {

    let displaySeconds = Math.round(seconds % 60);
    if (displaySeconds < 10) {
        displaySeconds = '0' + displaySeconds;
    }

    const totalMinutes = Math.floor(seconds / 60);
    let displayMinutes = totalMinutes % 60;
    if (displayMinutes < 10) {
        displayMinutes = '0' + displayMinutes;
    }

    const hours = Math.floor(totalMinutes / 60);

    let output = '';
    if (hours) {
        output += hours + ':';
    }
    output += displayMinutes;

    if (showSeconds) {
        output += ':' + displaySeconds;
    }
    return output;

};

const paceTotal = (pace, distance) => {
    const km = distance / 1000;
    const minutes = km * pace;
    const seconds = minutes * 60;
    return paceTimeFormat(seconds);
};

const PaceCalculator = () => {

        const distances = {
            '5k': 5000,
            '10k': 10000,
            'Half Marathon': 21098,
            'Marathon': 42195,
        };

        const paces = [];
        for (let newPace = 3; newPace <= 10; newPace += 0.5) {
            paces.push(newPace);
        }

        const rows = [];
        for (const pace of paces) {
            const secondsPerKm = pace * 60;

            const row = [paceTimeFormat(secondsPerKm)];
            for (const distance in distances) {
                row.push(paceTotal(pace, distances[distance]));
            }
            rows.push(row);
        }

        return (
            <table>
                <thead>
                <th>Pace (minutes per km)</th>
                {Object.keys(distances).map(distance => (
                    <th>{distance}</th>
                ))}
                </thead>
                <tbody>
                {rows.map(row => (
                    <tr>
                        {row.map(cell => (
                            <td>{cell}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        )
    }
;

export default PaceCalculator;
