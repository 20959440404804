import React from "react";


class ImageResizingCalculator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            origWidth: '',
            origHeight: '',
            newWidth: '',
            newHeight: '',
            newPct: '',
            ratio: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: parseInt(value)
        }, this.updateSizes);

        const newSizeFields = [
          'newWidth',
          'newHeight',
          'newPct'
        ];

        if (newSizeFields.includes(name)) {
            for (const field of newSizeFields) {
                if (field !== name) {
                    const els = document.getElementsByName(field);
                    els[0].value = '';
                    this.setState({
                        [field]: ''
                    }, this.updateSizes);
                }
            }
        }
    }

    calculateNewSize() {
        let {origWidth, origHeight, newWidth, newHeight, newPct, ratio} = this.state;

        if (ratio) {
            if (newWidth) {
                newHeight = newWidth / ratio;
                newPct = (newWidth / origWidth * 100).toFixed(2);
            }
            else if (newHeight) {
                newWidth = newHeight * ratio;
                newPct = (newHeight / origHeight * 100).toFixed(2);
            }
            else if (newPct) {
                newWidth = origWidth * newPct / 100;
                newHeight = origHeight * newPct / 100;
            }

        }

        this.setState({origWidth, origHeight, newWidth, newHeight, newPct, ratio});
    }

    updateSizes() {
        if (this.state.origWidth && this.state.origHeight) {
            const ratio = (this.state.origWidth / this.state.origHeight);
            this.setState({
                ratio
            }, this.calculateNewSize);
        }
    }

    numberFormat(number) {
        return parseFloat(Number.parseFloat(number).toFixed(2));
    }

    render() {

        let origBox = '';
        if (this.state.origWidth && this.state.origHeight) {
            origBox = (
                <fieldset className="half-width">
                    <legend>Original {this.state.origWidth} x {this.state.origHeight}</legend>
                    <div style={{
                        width: this.state.origWidth,
                        height: this.state.origHeight,
                        backgroundColor: '#fcc',
                        border: '1px solid'
                    }}/>
                </fieldset>
            );
        }

        let newBox = '';
        if (this.state.newWidth && this.state.newHeight) {
            newBox = (
                <fieldset className="half-width">
                    <legend>New: {this.numberFormat(this.state.newWidth)} x {this.numberFormat(this.state.newHeight)} - {this.numberFormat(this.state.newPct)} % of original</legend>
                    <div style={{
                        width: this.state.newWidth,
                        height: this.state.newHeight,
                        backgroundColor: '#f60',
                        border: '1px solid'
                    }}/>
                </fieldset>
            );
        }

        return (
            <div>
                <form className="flex-wrapper">
                    <fieldset className="half-width">
                        <legend>Original image</legend>
                        <label htmlFor="origWidth" className="form-label">Original width</label>
                        <input name="origWidth" type="number" className="form-input" ref={this.origWidthInput}
                               onChange={this.handleChange}/>

                        <label htmlFor="origHeight" className="form-label">Original height</label>
                        <input name="origHeight" type="number" className="form-input" value={this.state.origHeight}
                               onChange={this.handleChange}/>

                        <label htmlFor="ratio" className="form-label">Ratio</label>
                        <input name="ratio" type="number" disabled={true} className="form-input form-input-inline"
                               value={this.state.ratio} onChange={this.handleChange}/> : 1
                    </fieldset>

                    <fieldset className="half-width">
                        <legend>New image size</legend>
                        <p>Set one value</p>
                        <label htmlFor="newWidth" className="form-label">New width</label>
                        <input name="newWidth" type="number" className="form-input"
                               onChange={this.handleChange}/>

                        <label htmlFor="newHeight" className="form-label">New height</label>
                        <input name="newHeight" type="number" className="form-input"
                               onChange={this.handleChange}/>

                        <label htmlFor="newPct" className="form-label">New size as % of old size</label>
                        <input name="newPct" type="number" className="form-input"
                               onChange={this.handleChange}/>

                    </fieldset>

                    {origBox}
                    {newBox}
                </form>

            </div>
        )
    }
}

export default ImageResizingCalculator;
