/**
 * Template for an individual code post.
 */

import React from "react"
import {Link, graphql} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Tags from "../components/tags";
import Sidebar from "../components/sidebar";
import CodeList from "../components/code-list";

const Components = {};

const renderComponent = (name) => {
    if (name) {
        const RenderComp = Components[name];
        return <RenderComp/>;
    }

    return (
        ''
    )
};

const CodePostTemplate = ({data, location}) => {

    const post = data.markdownRemark;

    const siteTitle = data.site.siteMetadata?.title || `Title`;
    const {previous, next, quote, music} = data;

    const filename = post.parent.name;
    const componentName = post.frontmatter.component;

    Components[componentName] = require(`../scripts/${filename}`).default;

    return (
        <Layout location={location} title={siteTitle}>
            <Seo
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />

            <Sidebar quote={quote} music={music}><CodeList/></Sidebar>

            <div className='main-content'>
                <article
                    className="blog-post"
                    itemScope
                    itemType="http://schema.org/Article"
                >
                    <header>
                        <h1 itemProp="headline">{post.frontmatter.title}</h1>
                    </header>
                    <section
                        dangerouslySetInnerHTML={{__html: post.html}}
                        itemProp="articleBody"
                    />
                    <Tags tags={post.frontmatter.tags}/>
                </article>

                <div className="content code-component">
                    {renderComponent(post.frontmatter.component)}
                </div>

                <nav className="blog-post-nav">
                    <ul className="prev-next">
                        {previous && (
                            <li>
                                <Link to={previous.frontmatter.slug} rel="prev" className="prev-next-link link-previous">
                                    {previous.frontmatter.title}
                                </Link>
                            </li>
                        )}
                        {next && (
                            <li>
                                <Link to={next.frontmatter.slug} rel="next" className="prev-next-link link-next">
                                    {next.frontmatter.title}
                                </Link>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>

        </Layout>
    )
};

export default CodePostTemplate

export const pageQuery = graphql`
  query CodeBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $quoteId: String
    $musicId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        component
      }
      parent {
          ... on File {
            id
            name
          }
        }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      frontmatter {
        title
        slug
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      frontmatter {
        title
        slug
      }
    }
    quote: markdownRemark(id: { eq: $quoteId }) {
      id
      html
      frontmatter {
        title
        author
        source
        source_link
      }
    }
    music: markdownRemark(id: { eq: $musicId }) {
        id
        frontmatter {
          title
          slug
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        html
    }
  }
`;
