/**
 * Image Layout Size Calculator code experiment.
 */

import React from "react";

class ImageLayoutSizeCalculator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rectangleX: 680,
            rectangleY: 390,
            largeX: 336,
            largeY: 390,
            smallX: 336,
            smallY: 191,
            margin: 8,
            increaseMain: 0
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.updateSizes();
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: parseInt(value)
        }, this.updateSizes);
    }

    updateSizes() {
        const halfRectangle = Math.floor((this.state.rectangleX - this.state.margin) / 2);
        const imageWrapperStyle = {
            float: 'left',
            position: 'relative'
        };

        const smallX = halfRectangle - this.state.increaseMain;
        const smallY = Math.floor((this.state.rectangleY - this.state.margin) / 2);
        const largeX = halfRectangle + this.state.increaseMain;
        const largeY = this.state.rectangleY;

        const smallStyle = {
            ...imageWrapperStyle,
            width: smallX,
            height: smallY
        };

        const small1Style = {
            ...smallStyle,
            marginBottom: this.state.margin
        };

        this.setState({
            smallX,
            largeX,
            largeY,
            smallY,
            rectangleStyle: {
                width: this.state.rectangleX,
                height: this.state.rectangleY,
                marginTop: 20
            },
            largeStyle: {
                ...imageWrapperStyle,
                width: largeX,
                height: largeY,
                marginRight: this.state.margin
            },
            smallStyle,
            small1Style
        });
    }

    render() {

        return (
            <div>
                <form className="flex-wrapper">
                    <div className="half-width">
                        <label htmlFor="rectangleX" className="form-label">The width of the containing rectangle (in
                            pixels)</label>
                        <input name="rectangleX" type="number" onChange={this.handleChange}
                               defaultValue={this.state.rectangleX} className="form-input"/>
                    </div>

                    <div className="half-width">
                        <label htmlFor="rectangleY" className="form-label">The height of the containing rectangle (in
                            pixels)</label>
                        <input name="rectangleY" type="number" onChange={this.handleChange}
                               defaultValue={this.state.rectangleY} className="form-input"/>
                    </div>

                    <div className="half-width">
                        <label htmlFor="margin" className="form-label">The margin between the inner rectangles (in
                            pixels)</label>
                        <input name="margin" type="number" onChange={this.handleChange} defaultValue={this.state.margin}
                               className="form-input"/>
                    </div>

                    <div>
                        <label htmlFor="increaseMain" className="form-label">The amount (in pixels) to increase the
                            width of
                            the main image</label>
                        <input name="increaseMain" type="number" onChange={this.handleChange}
                               defaultValue={this.state.increaseMain} className="form-input"/>
                    </div>
                </form>

                <div id="imageSizes" className="flex-wrapper">
                    <div className="third-width">
                        <h3>Containing rectangle</h3>
                        <p>{this.state.rectangleX}px x {this.state.rectangleY}px</p>
                    </div>

                    <div className="third-width">
                        <h3>Large left image</h3>
                        <p>{this.state.largeX}px x {this.state.largeY}px</p>
                    </div>

                    <div className="third-width">
                        <h3>Small right images</h3>
                        <p>{this.state.smallX}px x {this.state.smallY}px</p>
                    </div>
                </div>

                <div id="rectangle" style={this.state.rectangleStyle}>
                    <div className="imageWrapper" id="large" style={this.state.largeStyle}>
                        <img src={'https://picsum.photos/' + this.state.largeX + '/' + this.state.largeY + '?random=1'}
                             title={this.state.largeX + ' x ' + this.state.largeY}
                             alt={'Placeholder image ' + this.state.largeX + ' x  ' + this.state.largeY}/>
                    </div>
                    <div className="imageWrapper" id="small1" style={this.state.small1Style}>
                        <img src={'https://picsum.photos/' + this.state.smallX + '/' + this.state.smallY + '?random=2'}
                             title={this.state.smallX + ' x  ' + this.state.smallY}
                             alt={'Placeholder image ' + this.state.smallX + ' x  ' + this.state.smallY}/>
                    </div>
                    <div className="imageWrapper" id="small2" style={this.state.smallStyle}>
                        <img src={'https://picsum.photos/' + this.state.smallX + '/' + this.state.smallY + '?random=3'}
                             title={this.state.smallX + ' x  ' + this.state.smallY}
                             alt={'Placeholder image ' + this.state.smallX + ' x  ' + this.state.smallY}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageLayoutSizeCalculator
