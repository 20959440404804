import React from "react";

class BytesCalculator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: 123456,
            units: 'B',
            bytes: 123456
        };

        this.units = {
            'B': {
                name: "byte",
                power: 0
            },
            'kB': {
                name: "kilobyte",
                power: 10
            },
            'MB': {
                name: "megabyte",
                power: 20
            },
            'GB': {
                name: "gigabyte",
                power: 30
            },
            'TB': {
                name: "terabyte",
                power: 40
            },
            'PB': {
                name: "petabyte",
                power: 50
            },
            'EB': {
                name: "exabyte",
                power: 60
            },
            'ZB': {
                name: "zettabyte",
                power: 70
            },
            'YB': {
                name: "yottabyte",
                power: 80
            }
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        }, this.updateState);
    }

    updateState() {
        this.setState({
            bytes: this.getBytes(this.state.value, this.state.units)
        });
    }

    numberFormat(number) {
        return parseFloat(Number.parseFloat(number).toFixed(2));
    }

    getBytes(value, unit) {
        const details = this.units[unit];
        return value * (Math.pow(2, details.power));
    }

    calculate(bytes, unit) {
        const result = bytes / Math.pow(2, this.units[unit].power);

        const places = (result % 1).toString().length;
        return result.toFixed(places);
    }

    prepareDisplay(value, unit) {
        return this.calculate(this.state.bytes, unit) + ' ' + unit;
    }

    render() {
        return (
            <div>
                <form>
                    <div>
                        <label htmlFor="value" className="form-label">Value</label>
                        <input name="value" type="number" className="form-input" value={this.state.value}
                               onChange={this.handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="units" className="form-label">Units</label>
                        <select name="units" className="form-input" onBlur={this.handleChange}>
                            {Object.keys(this.units).map(unit => (
                                <option value={unit} key={unit}>{this.units[unit].name}</option>
                            ))}
                        </select>
                    </div>
                </form>

                <table>
                    <thead>
                    <tr>
                        <th>Unit</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(this.units).map(unit => {
                        const unitName = this.units[unit].name;
                        return (
                            <tr key={unit}>
                                <td>
                                    <a target="_blank" rel="noreferrer" href={'http://en.wikipedia.org/wiki/' + unitName}>{unitName}</a></td>
                                <td>{this.prepareDisplay(this.state.value, unit)}</td>
                            </tr>
                        )}
                    )}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default BytesCalculator;
